@import "../shared";

.reportBug {
	position: fixed;
	bottom: 16px;
	right: 16px;
	font-size: 28px;
	color: #fff;
	background-color: rgb(204, 120, 255, .2);
	backdrop-filter: blur(10px);
	border: 2px solid #fff;
	width: 50px;
	height: 50px;
	border-radius: 8px;
	cursor: pointer;
}

.root {
	padding: 38px 42px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background: #3D3261;
	border-radius: 8px;
	grid-area: pricing;
	max-width: 570px;

	@include max(1200) {
		padding: 24px 18px 32px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
	@include max(1024) {
		margin-top: 16px;
	}

	.headerWrapper {
		width: 100%;
	}

	.onMarket {
		color: #FFF;
		text-align: right;
		font-size: 12px;
		line-height: 19px;
		margin-left: auto;
		span {
			color: #FFF;
			font-size: 10px;
			line-height: 19px;
		}
	}
}

.title {
	display: flex;
	width: 100%;
	color: #FFFFFF;
	align-items: center;
	margin-top: 4px;
	margin-bottom: 31px;
	font-family: var(--font-poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 23.6048px;
	line-height: 130.02%;

	@include max(1200) {
		font-size: 23.6048px;
		line-height: 130.02%;
		margin-top: 32px;
	}

	&:after {
		content: "";
		display: block;
		margin-left: 25px;
		flex-grow: 1;
		height: 1px;
		background-image: linear-gradient(90deg, #9E00FF 9.78%, rgba(158, 0, 255, 0) 87.17%);
	}
}

.purchaseBtn {
	@extend .btnFilled;
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.5px;
	color: #FFFFFF;
	padding: 16px 50px 16px 16px;
	box-shadow: 0 15px 41px rgba(0, 0, 0, 0.18);
	display: inline-flex;
	align-items: center;
	justify-content: space-evenly;
	position: relative;

	.purchaseBtnOwner {
		position: absolute;
		right: 16px;
		top: 0;
		margin: auto;
		bottom: 0;
	}
	@include max(1024) {
		font-size: 14px;
	}

	@include max(768) {
		font-size: 12px;
	}
}
.whatOfferBtn {
	@extend .btnTransparent;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.5px;
	color: #FFFFFF;
	padding: 16px;
	box-shadow: 0 15px 41px rgba(0, 0, 0, 0.18);
	display: inline-flex;
	align-items: center;
	justify-content: space-evenly;
	position: relative;
	@include max(1024) {
		font-size: 14px;
	}

	@include max(768) {
		font-size: 12px;
	}
}

.btnWrap {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;
	gap: 16px 8px;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;

	@include max(1300) {
		flex-direction: column;
		justify-content: revert;
		align-items: center;
	}
}

.priceList {
	width: 100%;
	list-style: none;
	padding: 0;
	font-size: 20px;

	li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;
		font-family: var(--font-poppins);
		font-size: 16px;
		line-height: 26px;
		color: #CFCFCF;

		&.hr {
			border: none;
			border-top: 1px solid #3B305F;
			line-height: 0;
			width: 100%;
			margin-top: 8px;
			margin-bottom: 9px;
		}

		&.total {
			font-weight: 500;
			font-size: 16px;
			line-height: 26px;
			color: #d082ff;
			padding-bottom: 10px;
			border-bottom: 1px solid #30254C;
			margin-bottom: 0;

			span {
				font-size: 20px;
				line-height: 26px;
			}
		}
	}

	ul > li {
		font-weight: normal;
	}

	span {
		font-size: 16px;
		line-height: 26px;
		color: #FFFFFF;
	}

	.priceDesc {
		font-size: 12px;
		line-height: 1.4;
		margin-top: 2px;
		text-align: right;
		color: #CFCFCF;
		justify-content: flex-end;
	}
}

.broker {
	position: absolute;
	bottom: 4px;
	right: 8px;
}
.host {
	display: flex;
	align-items: center;
	gap: 4px;
	flex-wrap: wrap;
	margin-top: auto;
	width: 100%;
	max-width: 250px;
	color: #CFCFCF;

	@include max(1300) {
		justify-content: center;
		text-align: center;
	}

	img {
		border-radius: 50%;
	}
}

.notes {
	width: 100%;
	color: #FFF;
	margin-top: auto;
	margin-bottom: auto;
	display: none;

	.notesBody {
		color: #CFCFCF;
		font-size: 14px;
		line-height: 140%; /* 19.6px */
		letter-spacing: 0.058px;
		margin-top: 3px;
	}
	.notesTitle {
		font-size: 18px;
		line-height: 26.239px;
	}
	@include max(1024) {
		.notesBody {
			font-size: 12px;
		}
		.notesTitle {
			font-size: 16px;
		}
	}
}

/*
.hostLink {
	display: flex;
	align-items: center;
	gap: 8px;
	color: #fff;

	&:hover, &:active {
		color: #CC78FF;
	}
}
*/
