@import "@css/shared";

.root {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    z-index: 100;
    padding: 8px;

    .backdrop {
        z-index: 0;
        background: rgba(204, 120, 255, 0.13);
        backdrop-filter: blur(37px);
        position: absolute;
        inset: 0;
    }
}

.container {
    overflow: auto;
    width: 1060px;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    background: #423072;
    display: flex;
    border-radius: 16px;
    z-index: 2;
    position: relative;
    -webkit-user-drag: none;
    box-shadow: 0 20px 50px #00000052, 0 2px 12px #0003;
    @include max(1024) {
        width: 768px;
    }

    .closeBtn {
        position: absolute;
        right: 37px;
        top: 34px;
        background-color: transparent;
        display: flex;
        align-items: center;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    .pricing {
        max-width: 423px;
        width: 400px;
        flex-shrink: 0;
        background-color: #30254C;
        padding: 28px 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        @include max(1024) {
            display: none;
        }

        .btnWrap {
            display: none;
        }

        .headerWrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 18px;
            gap: 8px;
        }

        .onMarket {
            font-family: var(--font-poppins);
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.05833333358168602px;
            text-align: right;
            min-width: 150px;
        }

        .daysOnMarket {
            font-size: 10px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.05833333358168602px;
            text-align: right;
        }

        .pricingResortName {
            font-family: var(--font-lora);
            font-size: 22px;
            font-weight: 500;
            line-height: 30.72px;
            letter-spacing: 0.4699999988079071px;
            text-align: left;
            color: #CC78FF;
        }
    }
}

.suggestionSide {
    padding: 60px 32px 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;


    @include max(1024) {
        padding: 16px;
        max-width: 100%;
        background: #30254C;
    }
    @include max(375) {
        padding: 16px 8px;
    }

    h2 {
        margin-bottom: 12px;
        font-family: var(--font-lora);
        font-size: 20px;
        font-weight: 500;
        line-height: 25.6px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .beta {
        color: #38FFAB;
        font-size: 12px;
        line-height: 22px;
    }

    .loadingWrap {
        display: flex;
        height: 78px;
        justify-content: center;
        align-items: center;
    }

    .suggestionContent {
        flex-grow: 1;
        background-color: #30254C;
        border-radius: 13px;
        padding: 22px 26px;
        position: relative;

        @include max(1024) {
            padding: 22px 0;
            background-color: transparent;
        }
    }

    .suggestionInfo {
        box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
        position: relative;
        padding: 11px 19px;
        margin-top: 30px;

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, #FFFFFF, rgba(255, 255, 255, 0) 76.83%);
            border-radius: 8px;
        }

        &:after {
            content: "";
            position: absolute;
            inset: 1px;
            background: #423072;
            border-radius: 8px;
        }

        .infoContent {
            position: relative;
            z-index: 1;

            h3 {
                font-family: var(--font-poppins);
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
            }
        }

        .infoText {
            display: flex;
            gap: 16px;

            @include max(560) {
                flex-direction: column;
                .suggestionImg {
                    width: auto;
                    height: auto;
                    max-height: 120px;
                }
            }
        }

        .textBlocks {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-family: var(--font-poppins);
            font-size: 12px;
            font-weight: 400;
            line-height: 15.4px;
            letter-spacing: 0.4699999988079071px;
            text-align: left;

            > p {
                display: flex;
                gap: 12px;
                margin-bottom: 0;
                color: #CFCFCF;
                align-items: center;
            }

            img {
                max-width: 25px;
                max-height: 25px;
            }
        }

        .imgWrap {
            position: relative;
            align-self: center;

            &:before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 8px;
                background: linear-gradient(180deg, #FFFFFF, rgba(255, 255, 255, 0) 76.83%);
            }

            .suggestionImg {
                margin: 1px;
                position: relative;
                border-radius: 8px;
            }
        }
    }
    .interactiveContent {
        display: flex;
        flex-direction: column;
    }

    .ratingGroups {
        display: flex;
        background: #271E42;
        margin-top: 4px;
        border-radius: 4px;
        padding: 50px 0 16px;
        user-select: none;
        pointer-events: none;
    }

    .ratingSlot {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% / 4 - 33px);
        font-size: 0;
        @include max(480) {
            padding-left: 4px;
            padding-right: 4px;
        }

        .stars {
            --size: 16px;
            --gap: 3px;
            @include max(570) {
                --size: 10px;
                --gap: 1px;
            }
        }

        .slotContent {
            font-family: var(--font-poppins);
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.06px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            @include max(570) {
                .dealText {
                    display: none;
                }
            }
        }

    }

    .ratingSlotSpace {
        width: 11px;
        position: relative;
        flex-shrink: 0;

        &:before {
            content: '';
            position: absolute;
            height: 240%;
            background-color: #555555;
            width: 1px;
            bottom: 0;
            left: 50%;
        }
    }


    .gradientBar {
        width: 100%;
        height: 10px;
        background: linear-gradient(90deg, rgb(254, 90, 159) 0%, rgb(255, 228, 160) 22%, rgb(255, 228, 160) 41%, rgb(56, 255, 171) 100%);
        position: relative;
        z-index: 1;
        cursor: e-resize;
        -webkit-user-drag: none;
    }

    .cursor {
        width: 10px;
        border: 2.5px solid #30254C;
        background-color: #CFCFCF;
        position: absolute;
        border-radius: 100%;
        left: var(--left, 0);
        height: 23px;
        top: 50%;
        transform: translate(-50%, -50%) scale(var(--scale, 1));
        z-index: 5;
        cursor: e-resize;
        -webkit-user-drag: none;
    }

    .cursorInfo {
        position: absolute;
        bottom: 100%;
        left: max(min(var(--left, 0), calc(100% - 40px)), 40px);
        transform: translateY(-10px) translateX(-50%);
        padding: 2px 6px;
        border-radius: 12px;
        background: #3872eb;
        white-space: nowrap;
        font-size: 12px;
        user-select: none;
        pointer-events: none;
        transition: opacity 0.3s ease;
        z-index: 5;

    }

    .gradientWrap {
        position: relative;
        -webkit-user-drag: none;
        z-index: 2;
    }

    .gradientHeightWrap {
        padding-top: 76px;
    }

    .suggestedOffer {
        position: absolute;
        background-color: #CFCFCF;
        width: 6px;
        height: 20px;
        left: calc(var(--suggestedOffer, 0) - 3px);
        top: -5px;
        z-index: 2;
        border: 2.5px solid #30254C;
    }

    .realisticRange {
        position: absolute;
        top: 0;
        z-index: 1;
        height: 18px;
        border-radius: 7px;
        border: 1px dashed #CFCFCF;
        left: calc(50% + 25% / 2);
        user-select: none;
        pointer-events: none;
        -webkit-user-drag: none;
        touch-action: none;

        .arrowTop {
            position: absolute;
            left: 50%;
            top: 100%;
            margin-left: -1px;
            width: 2px;
            height: 34px;
            border-left: 1px dashed #CFCFCF;

            svg {
                position: absolute;
                left: -50%;
                transform: translate(-50%,-50%) rotateX(180deg);
                top: 4px;
            }
        }

        .lineRight {
            width: var(--distanceX, 0);
            position: absolute;
            top: 100%;
            left: var(--left);
            right: var(--right);
            height: 1px;
            border-bottom: 1px dashed #CFCFCF;
        }
    }

    .realisticRangeText {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(calc(100% + 18px));
        font-family: var(--font-poppins);
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        white-space: nowrap;
        -webkit-user-drag: none;
        padding: 0 6px;
        background-color: #271e42;
        user-select: none;
        pointer-events: none;
        touch-action: none;
    }

    .currentListingPrice {
        position: absolute;
        width: 100px;
        color: #CFCFCF;
        font-size: 15px;
        font-family: var(--font-poppins), serif;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        padding-bottom: 48px;
        left: max(min(var(--left), 93%), 7%);
        bottom: 100%;
        pointer-events: none;
        user-select: none;
        transform: translateX(-50%);
    }

    .currentListingPriceArrow {
        position: absolute;
        bottom: 0;
        height: 52px;
        width: 2px;
        left: var(--left);
        margin-left: -1px;
        background-color: #CFCFCF;

        svg {
            position: absolute;
            transform: rotateX(180deg) translateX(-50%);
            top: -8px;
            left: 50%;

            path {
                stroke: #CFCFCF;
            }
        }
    }
}
